import axios from "axios";

const state = () => ({
    locations: [],
});

const actions = {
    async fetchLocations({ commit }, api_info) {
        /*
        await axios
            .get(`${api_info}`)
            .then(res => {
                let resultData = res.data.data;
                let result =[];
                 resultData.map((e,i) => {
                    result = result.concat(e.locations.map(x => {
                        return {
                            text: e.name +','+ x.name,
                            value: x.id
                        }
                    }))
                });                
                commit('SET_LOCATIONS', result)
            })
            .catch(err => {})
         */
        return {
            "id": 12,
            "name": "منطقة دمشق القديمة",
            "longitude": "36.31107",
            "latitude": "33.511486",
            "locations": [
                {
                    "id": 13,
                    "name": "حارة الجورة",
                    "longitude": "36.313536",
                    "latitude": "33.513801",
                    "location_full_name": "منطقة دمشق القديمة - حارة الجورة",
                    "parent": {
                        "id": 12,
                        "name": "منطقة دمشق القديمة",
                        "longitude": "36.31107",
                        "latitude": "33.511486",
                        "location_full_name": "دمشق - منطقة دمشق القديمة",
                        "parent": {
                            "id": 1,
                            "name": "دمشق",
                            "longitude": "36.4736585,11",
                            "latitude": "33.5120313",
                            "location_full_name": "دمشق",
                            "parent": null
                        }
                    }
                }
            ]
        };
    },

    async fetchGovernorate({ commit }, api_info) {
        await axios
            .get(`${api_info}`)
            .then(res => {
                let resultData = res.data.data;
                let result = resultData.map(x => {
                    return {
                        text: x.name,
                        value: x.id
                    }
                })
                commit('SET_LOCATIONS', result)
            })
            .catch(err => {})
    },
}

const getters = {
    getLocations: state => {
        return state.locations
    },
}

const mutations = {
    SET_LOCATIONS(state, payload) {
        state.locations = payload
    },
}


export default {
    state,
    getters,
    mutations,
    actions
}